import React, {Component} from 'react'
import './Jauge.scss'
import Motion from 'react-motion/lib/Motion'
import {spring} from 'react-motion/lib/react-motion'

export class Jauge extends Component {
	render() {
		const {color, value, maxValue} = this.props
		const widthFilled = value * 100 / maxValue
		return (
		  <div className="jauge">
			  <Motion defaultStyle={{width: 0}} style={{width: spring(widthFilled)}}>
				  {interpolatingStyle => <div className={`filled ${color}`} style={{width: `${interpolatingStyle.width}%`}}></div>}
			  </Motion>
			  <span className="digits">{value} / {maxValue}</span>
		  </div>
		)
	}
}
