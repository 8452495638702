import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect'
import * as playerActions from '../../store/player/playerActions'
import {getPlayer} from '../../store/player/playerSelector'
import DiceThrowDialog from './DiceThrowDialog'
import QRCodeDialog from './QRCodeDialog'
import ImageDialog from './ImageDialog'
import * as EquipementService from '../../api/EquipementService'
import AttaqueDefenseDialog from './AttaqueDefenseDialog'
import './PlayerActions.scss'
import Popup from "./Popup";

const ActionButton = ({ skin, onClick }) => {
	return (
		<div className="action-button" onClick={onClick}>
			<div className={`${skin}-action-btn`}></div>
		</div>
	)
}

class PlayerActions extends Component {

    constructor(props) {
        super(props)
        this.state = {diceThrowDialogOpened: false, qrCodeSelectorOpened: false, imageDialogOpened: false, attaqueDefenseDialogOpened: false, popupOpened: false}
    }

    openAttaqueDefenseDialog = () => this.setState({...this.state, attaqueDefenseDialogOpened: true})
    closeAttaqueDefenseDialog = () => this.setState({...this.state, attaqueDefenseDialogOpened: false})

    openDiceThrowDialog = (dice, withContreAttaque) => this.setState({...this.state, diceThrowDialogOpened: true, throwData : {dice, withContreAttaque}})
    closeDiceThrowDialog = () => this.setState({...this.state, diceThrowDialogOpened: false})

    openQrCodeDialog = () => this.setState({...this.state, qrCodeSelectorOpened: true})
    closeQrCodeDialog = () => this.setState({...this.state, qrCodeSelectorOpened: false})

    openImageDialog = (image) => this.setState({...this.state, imageDialogOpened: true, image, titre: 'Nouvel équipement'})
    closeImageDialog = () => this.setState({...this.state, imageDialogOpened: false})

    openPopup = (message) => this.setState({...this.state, popupOpened: true, message})
    closePopup = () => this.setState({...this.state, popupOpened: false})

    onQrCodeScan = async (id) => {
    	const {player, putPlayerEquipement} = this.props;
    	try {
            await putPlayerEquipement(player.id, id);
            this.openImageDialog(await EquipementService.getCarte(id))
        } catch (err) {
            this.openPopup(err.data.message)
        }
    }

    onAttaqueDefenseChoice = (dice, withContreAttaque) => {
        this.openDiceThrowDialog(dice, withContreAttaque);
    }

	render() {
		return (
		  <div className="player-actions">

			  <div className="player-actions-top-middle">
				  <div className="action-button-container small">
				    <ActionButton skin="stuff" onClick={this.openQrCodeDialog} />
				  </div>
			  </div>

			  <div className="player-actions-bottom-left">
				  <div className="action-button-container">
				    <ActionButton skin="attaque" onClick={this.openAttaqueDefenseDialog} />
				  </div>
			  </div>
			  <div className="player-actions-bottom-right">
				  <div className="action-button-container">
				    <ActionButton skin="move" onClick={() => this.openDiceThrowDialog(['D6', 'D6'], false)} />
				  </div>
			  </div>

              <AttaqueDefenseDialog handleClose={this.closeAttaqueDefenseDialog} open={this.state.attaqueDefenseDialogOpened} callback={this.onAttaqueDefenseChoice} />
              <DiceThrowDialog throwData={this.state.throwData} handleClose={this.closeDiceThrowDialog} open={this.state.diceThrowDialogOpened} />
              <QRCodeDialog handleClose={this.closeQrCodeDialog} open={this.state.qrCodeSelectorOpened} callback={this.onQrCodeScan} />
              <ImageDialog image={this.state.image} titre={this.state.titre} handleClose={this.closeImageDialog} open={this.state.imageDialogOpened} />
              <Popup message={this.state.message} handleClose={this.closePopup} open={this.state.popupOpened}  />
		  </div>
		)
	}
}


const mapStateToProps = (state, props) => ({
	player: getPlayer(state)
})

export default connect(
  mapStateToProps, {
	  loadPlayerAction: playerActions.loadPlayer,
        putPlayerEquipement: playerActions.putPlayerEquipement
  }
) (PlayerActions)