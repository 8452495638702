import {createSelector} from 'reselect'

export const getPlayer = (state) => state.player

const getCompetenceNiveau = (player, competence) => {
	return player && player.competences && player.competences.slice().reverse().find(t => t.competence === competence).niveau
}

export const getPlayerStatsMax = createSelector(
  getPlayer,
  (player) => {
	  if (!player) return null
	  return {
		  vie: getCompetenceNiveau(player, 'VIE'),
		  mana: getCompetenceNiveau(player, 'MANA'),
		  esprit: getCompetenceNiveau(player, 'ESPRIT'),
		  cac: getCompetenceNiveau(player, 'CORPS_A_CORPS'),
		  tir: getCompetenceNiveau(player, 'TIR'),
		  defense: getCompetenceNiveau(player, 'DEFENSE'),
		  magie: getCompetenceNiveau(player, 'MAGIE')
	  }
  }
)