import React from 'react'
import loaderBlue from '../../resources/img/loader/spinner-blue.svg'
import loaderWhite from '../../resources/img/loader/spinner.svg'

export const Loader = ({ color, width }) => {
	const loader = color === 'white' ? loaderWhite : loaderBlue
	return (
	  <img src={loader} width={width || 'auto'} height="auto" alt="" role="presentation" />
	)
}
