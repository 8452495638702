export const storeAccessToken = (accessToken) => {
    return localStorage.setItem('session:accessToken', accessToken)
}

export const loadAccessToken = () => {
    return localStorage.getItem('session:accessToken')
}

export const clearSession = () => {
    localStorage.removeItem('session:accessToken')
}
