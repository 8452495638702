import {get, patch, post, put} from './Service'
import * as SessionStorage from "../storage/SessionStorage";

export const getAll = () => {
    return get('/personnages', null, SessionStorage.loadAccessToken())
};

export const getOne = (id) => {
    return get(`/personnages/${id}`, null, SessionStorage.loadAccessToken())
};

export const patchVie = (id, newValue) => {
    return patch(`/personnages/${id}/vie`, newValue, SessionStorage.loadAccessToken())
};

export const patchEsprit = (id, newValue) => {
    return patch(`/personnages/${id}/esprit`, newValue, SessionStorage.loadAccessToken())
};

export const patchMana = (id, newValue) => {
    return patch(`/personnages/${id}/mana`, newValue, SessionStorage.loadAccessToken())
};

export const patchGold = (id, newValue) => {
    return patch(`/personnages/${id}/gold`, newValue, SessionStorage.loadAccessToken())
};

export const patchExperience = (id, newValue) => {
    return patch(`/personnages/${id}/experience`, newValue, SessionStorage.loadAccessToken())
};

export const addCompetence = (id, competenceToAdd) => {
    return put(`/personnages/${id}/competences`, competenceToAdd, SessionStorage.loadAccessToken())
};

export const addEquipement = (id, idEquipementToAdd) => {
    return post(`/personnages/${id}/equipements/${idEquipementToAdd}`, null, SessionStorage.loadAccessToken())
};
