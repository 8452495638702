import {types} from './parametrageActions'

const parametrage = (state = null, action) => {
    switch (action.type) {
        case types.LOAD_PARAMETRAGE_NIVEAUX_COMPETENCE: {
            if (action.payload) {
                return {
                    ...state,
                    niveauxCompetenceMap: action.payload
                }
            }
            return null
        }
        default:
            return state
    }
}

export default parametrage