export const DiceFaceEnum = {
	CRANE: 'CRANE',
	BOUCLIER: 'BOUCLIER',
	MONSTRE: 'MONSTRE',
	CRANE_DOUBLE: 'CRANE_DOUBLE',
	BOUCLIER_DOUBLE: 'BOUCLIER_DOUBLE',
	MONSTRE_DOUBLE: 'MONSTRE_DOUBLE',
	REUSSITE_CRITIQUE: 'REUSSITE_CRITIQUE',
	REUSSITE: 'REUSSITE',
	ECHEC: 'ECHEC'
}
