import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog'
import connect from 'react-redux/es/connect/connect'
import QrReader from "react-qr-reader";
import {getPlayer} from "../../store/player/playerSelector";

class QRCodeDialog extends Component {

	handleScan = data => {
		if (data) {
			this.props.callback(data);
			this.props.handleClose();
		}
	}

	handleError = err => {
		console.error(err)
	}

	render() {
		const {player, handleClose, open} = this.props

        if(!player) return <div/>

		return (
			<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'md'}>
				<QrReader
					delay={300}
                    onScan={this.handleScan}
					onError={this.handleError}
					style={{ width: '100%' }}
					/>
			</Dialog>
		)
	}
}


const mapStateToProps = (state, props) => ({
    player: getPlayer(state)
})

export default connect(
  mapStateToProps, {
  }
) (QRCodeDialog)
