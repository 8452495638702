import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect'
import * as playerActions from '../../store/player/playerActions'
import {getPlayer} from '../../store/player/playerSelector'
import './PlayerCompteurs.scss'
import {StatCompteur} from './compteur/StatCompteur'
import LevelUpDialog from './LevelUpDialog'

class PlayerCompteurs extends Component {

    constructor(props) {
        super(props)
        this.state = {levelUpOpened: false}
    }

    closeLevelUpSelector = () => {
        this.setState({levelUpOpened: false})
    }

    openLevelUpSelector = () => {
        document.getElementsByClassName("audio-element")[0].play();
        this.setState({levelUpOpened: true});
    }

	componentDidUpdate(prevProps) {
		if (this.props.player.niveau > prevProps.player.niveau) {
			this.openLevelUpSelector();
		}
	}

	udpateVie = (value) => {
		const {player, patchPlayerVie} = this.props;
		patchPlayerVie(player.id, value);
	}

	updateMana = (value) => {
		const {player, patchPlayerMana} = this.props;
		patchPlayerMana(player.id, value);
	}

	udpateEsprit = (value) => {
		const {player, patchPlayerEsprit} = this.props;
		patchPlayerEsprit(player.id, value);
	}

	updateExperience = (value) => {
		const {player, patchPlayerExperience} = this.props;
		patchPlayerExperience(player.id, value);
	}

	render() {
		const {player} = this.props

		if (!player) {
			return <div/>
		}

		return (
		  <div className="player-compteurs">
			  <div>
			    <StatCompteur value={player.vie} color="red" onChangeValue={this.udpateVie}/>
			  </div>
			  <div>
			    <StatCompteur value={player.esprit} color="green" onChangeValue={this.udpateEsprit}/>
			  </div>
			  <div>
			    <StatCompteur value={player.mana} color="blue" onChangeValue={this.updateMana}/>
			  </div>
			  <div>
			    <StatCompteur value={player.experience} color="violet" onChangeValue={this.updateExperience}/>
			  </div>
              <LevelUpDialog handleClose={this.closeLevelUpSelector} open={this.state.levelUpOpened} disableBackdropClick={true} />
              <audio className="audio-element">
                  <source src={require(`../../resources/sound/level-up.mp3`)} />
              </audio>
		  </div>
		)
	}
}

const mapStateToProps = (state, props) => ({
	player: getPlayer(state)
})

export default connect(
  mapStateToProps, {
  		loadPlayerAction: playerActions.loadPlayer,
		patchPlayerVie: playerActions.patchPlayerVie,
		patchPlayerEsprit: playerActions.patchPlayerEsprit,
		patchPlayerMana: playerActions.patchPlayerMana,
		patchPlayerGold: playerActions.patchPlayerGold,
		patchPlayerExperience: playerActions.patchPlayerExperience
  }
) (PlayerCompteurs)
