import React, {Component} from 'react'

export class Dice extends Component {
  render() {
    const {faceUp, dice, width} = this.props

      if(dice.color === '#000000') {
          return (<img src={require(`../../resources/img/dice/` + faceUp + `_BLANC.png`)} width={width}
                       style={{backgroundColor: dice.color}} alt="dice"/>)
      }
      else {
          return (<img src={require(`../../resources/img/dice/` + faceUp + `.png`)} width={width}
                       style={{backgroundColor: dice.color}} alt="dice"/>)
      }
  }
}