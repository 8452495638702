import {getAll} from "../../api/PersonnagesService";

const ACTION_NAMESPACE = '/PERSONNAGES'

export const types = {
    LOAD_PERSONNAGES: `${ACTION_NAMESPACE}/GET_ALL`,
}

export const loadPersonnages = () => async (dispatch) => {
    let personnages = await getAll()
    return dispatch({
        type: types.LOAD_PERSONNAGES,
        payload: personnages
    })
}

