import { combineReducers } from 'redux'
import player from './player/playerReducer'
import personnages from './personnages/personnagesReducer'
import parametrage from './parametrage/parametrageReducer'

const rootReducer = combineReducers({
	personnages,
    player,
    parametrage
})

export default rootReducer
