import React from 'react'
import './StatCompteur.scss'

export const StatValue = ({color, value, className}) => {
	return (
	  <div className={`stat-value-container ${className}`}>
		  <div className={`stat-value-frame skin-${color}`}>
			  {value}
		  </div>
	  </div>
	)
}

export const StatCompteur = ({color, value, onChangeValue}) => {
	return (
	  <div className="stat-compteur">
		  <div className="arrow-up" onClick={() => onChangeValue(value + 1)}></div>
		  <StatValue value={value} color={color} />
		  <div className="arrow-down" onClick={() => onChangeValue(value - 1)}></div>
	  </div>
	)
}
