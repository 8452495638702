import React, {Component} from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import {blue} from '@material-ui/core/colors'
import connect from 'react-redux/es/connect/connect'
import * as playerActions from '../../store/player/playerActions'
import StatsIcon from '../icons/StatsIcon'
import * as personnagesActions from "../../store/personnages/personnagesActions";
import * as personnagesSelector from "../../store/personnages/personnagesSelector";

class HeroChoiceDialog extends Component {

    componentWillMount() {
		this.props.loadPersonnagesAction();
    }

	selectHero = (hero) => {
		const { handleClose } = this.props
		this.props.loadPlayerAction(hero)
		handleClose()
	}

	render() {
		const {personnages, handleClose, open} = this.props

		return (
		  <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			  <DialogTitle id="simple-dialog-title">Choisis ton héros !</DialogTitle>
			  <List>
				  {
                      personnages && personnages.map((hero, key) => (
						  <ListItem key={key} button onClick={() => this.selectHero(hero)}>
							  <ListItemAvatar>
								  <Avatar styles={{backgroundColor: blue[100], color: blue[600]}}>
									  <StatsIcon />
								  </Avatar>
							  </ListItemAvatar>
							  <ListItemText primary={hero.nom}/>
						  </ListItem>
						)
					  )
				  }
			  </List>
		  </Dialog>
		)
	}
}


const mapStateToProps = (state, props) => ({
    personnages: personnagesSelector.getPersonnages(state)
})

export default connect(
  mapStateToProps, {
  	loadPlayerAction: playerActions.loadPlayer,
        loadPersonnagesAction: personnagesActions.loadPersonnages
  }
) (HeroChoiceDialog)