import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect'
import * as playerActions from '../../store/player/playerActions'
import {getPlayer, getPlayerStatsMax} from '../../store/player/playerSelector'
import './PlayerStats.scss'
import {Jauge} from './jauge/Jauge'
import {SkillField} from './skill/SkillField'
import Popup from "./Popup";

class PlayerStats extends Component {

    constructor(props) {
        super(props)
        this.state = {popupOpened: false}
    }

    closePopup = () => {
        this.setState({popupOpened: false})
    }

    openPopup = (titre, message) => {
        this.setState({popupOpened: true, titre, message});
    }

	render() {
		const {player, statsMax} = this.props

		if (!player) {
			return <div/>
		}

        const isPassifActivated = player.vie <= 2
		const isDead = player.vie === 0
		const isTired = player.esprit === 0
		let heroClassName = player.classe.toLowerCase()
		console.log(isPassifActivated)
        if (isDead) {
			heroClassName += '-dead'
		} else if (isTired) {
			heroClassName += '-tired'
		} else if (isPassifActivated) {
            heroClassName += '-passif'
        }

        return (
		  <div className="player-stats">
			  <div className="hero-header">
				  <div className={`hero-container`} onClick={() => isPassifActivated && player.passif && this.openPopup(player.passif.titre, player.passif.libelle)}>
					  <div className={`${heroClassName}`}></div>
					  <div className="level">
						  {player.niveau}
					  </div>
				  </div>
				  <div className="hero-status">
					  <div className="hero-name">
						  {player.nom}
					  </div>
					  <div className="jauges-container">
						  <Jauge color="red" value={player.vie} maxValue={statsMax.vie}></Jauge>
						  <Jauge color="green" value={player.esprit} maxValue={statsMax.esprit}></Jauge>
						  <Jauge color="blue" value={player.mana} maxValue={statsMax.mana}></Jauge>
						  <Jauge color="violet" value={player.experience} maxValue={player.experienceProchainNiveau}></Jauge>
					  </div>
				  </div>
			  </div>
              <div className="competences">
	              <div className="competences-principales">
		              <div className="competences-container"><SkillField value={statsMax.cac} type="cac"/></div>
		              <div className="competences-container"><SkillField value={statsMax.defense} type="defense"/></div>
		              <div className="competences-container"><SkillField value={statsMax.tir} type="tir"/></div>
	              </div>
	              <div className="competences-secondaires">
		              <div className="competences-container"><SkillField value={statsMax.magie} type="mana"/></div>
		              <div className="competences-container"><SkillField value={'-'} type="ecole-1" size="small" /></div>
		              <div className="competences-container"><SkillField value={'-'} type="ecole-2" size="small" /></div>
		              <div className="competences-container"><SkillField value={'-'} type="ecole-3" size="small" /></div>
		              <div className="competences-container"><SkillField value={'-'} type="ecole-4" size="small" /></div>
		              <div className="competences-container"><SkillField value={'-'} type="ecole-5" size="small" /></div>
	              </div>
              </div>
			  <Popup titre={this.state.titre} message={this.state.message} handleClose={this.closePopup} open={this.state.popupOpened}  />
		  </div>
		)
	}
}

const mapStateToProps = (state, _) => ({
	player: getPlayer(state),
	statsMax: getPlayerStatsMax(state)
})

export default connect(
  mapStateToProps, {
  		loadPlayerAction: playerActions.loadPlayer,
  }
) (PlayerStats)
