import React, {PureComponent} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {DiceEnum} from "../../types/DiceEnum";
import {Dice} from "../dice/Dice";
import {DiceFaceEnum} from "../../types/DiceFaceEnum";

class DiceThrowDialog extends PureComponent {

    // TODO GERER LA CONTRE-ATTAQUE

	render() {
		const {throwData, handleClose, open} = this.props

		if(!throwData || !throwData.dice) return <div/>

        const resultats = [];
        throwData.dice.forEach(de => {
        	const theDe = DiceEnum[de];
            const faces = theDe.faces;
            const face = faces[Math.floor(Math.random() * faces.length)];
            resultats.push({ de: theDe, face });
        });

        let contreAttaque = undefined;
        if(throwData.withContreAttaque) {
            contreAttaque = resultats.find(r => r.face === DiceFaceEnum.MONSTRE || r.face === DiceFaceEnum.MONSTRE_DOUBLE);
        }

		return (
		  <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			  {open && <div>
				  {resultats.map((res, j) => <Dice key={j} faceUp={res.face} dice={res.de} width="80px" />)}
                  {throwData.withContreAttaque && contreAttaque && <p style={{"text-align": "center"}}>Contre-attaque réussie</p>}
                  {throwData.withContreAttaque && !contreAttaque && <p style={{"text-align": "center"}}>Contre-attaque râtée</p>}
			  </div>}
		  </Dialog>
		)
	}

}

export default DiceThrowDialog
