import {
    addCompetence,
    addEquipement,
    getOne,
    patchEsprit,
    patchExperience,
    patchGold,
    patchMana,
    patchVie
} from '../../api/PersonnagesService'
import {getPlayer, getPlayerStatsMax} from './playerSelector'
import {clamp} from '../../utils/clamp'

const ACTION_NAMESPACE = '/PLAYER';

export const types = {
	LOAD_PLAYER: `${ACTION_NAMESPACE}/GET`,
    PATCH_PLAYER_VIE: `${ACTION_NAMESPACE}/PATCH_VIE`,
    PATCH_PLAYER_ESPRIT: `${ACTION_NAMESPACE}/PATCH_ESPRIT`,
    PATCH_PLAYER_MANA: `${ACTION_NAMESPACE}/PATCH_MANA`,
    PATCH_PLAYER_GOLD: `${ACTION_NAMESPACE}/PATCH_GOLD`,
    PATCH_PLAYER_EXPERIENCE: `${ACTION_NAMESPACE}/PATCH_EXPERIENCE`,
    PUT_PLAYER_COMPETENCE: `${ACTION_NAMESPACE}/PUT_COMPETENCE`,
    PUT_PLAYER_EQUIPEMENT: `${ACTION_NAMESPACE}/PUT_EQUIPEMENT`,
}

export const loadPlayer = (player) => async dispatch => {
    let personnage = await getOne(player.id);
	return dispatch({
		type: types.LOAD_PLAYER,
		payload: personnage
	})
}

export const patchPlayerVie = (id, newValue) => async (dispatch, getState) => {
    const player = getPlayer(getState())
    const statsMax = getPlayerStatsMax(getState())
    const maxValue = statsMax && statsMax.vie
    newValue = clamp(newValue, 0, maxValue)
    if (newValue !== player.vie) {
        patchVie(id, newValue);
        return dispatch({
            type: types.PATCH_PLAYER_VIE,
            payload: newValue
        })
    }
}

export const patchPlayerEsprit = (id, newValue) => async (dispatch, getState) => {
    const player = getPlayer(getState())
    const statsMax = getPlayerStatsMax(getState())
    const maxValue = statsMax && statsMax.esprit
    newValue = clamp(newValue, 0, maxValue)
    if (newValue !== player.esprit) {
        patchEsprit(id, newValue);
        return dispatch({
            type: types.PATCH_PLAYER_ESPRIT,
            payload: newValue
        })
    }
}

export const patchPlayerMana = (id, newValue) => async (dispatch, getState) => {
    const player = getPlayer(getState())
    const statsMax = getPlayerStatsMax(getState())
    const maxValue = statsMax && statsMax.mana
    newValue = clamp(newValue, 0, maxValue)
    if (newValue !== player.mana) {
        patchMana(id, newValue);
        return dispatch({
            type: types.PATCH_PLAYER_MANA,
            payload: newValue
        })
    }
}

export const patchPlayerGold = (id, newValue) => async dispatch => {
    let personnage = await patchGold(id, newValue);
    return dispatch({
        type: types.PATCH_PLAYER_GOLD,
        payload: personnage
    })
}

export const patchPlayerExperience = (id, newValue) => async dispatch => {
    let personnage = await patchExperience(id, newValue);
    return dispatch({
        type: types.PATCH_PLAYER_EXPERIENCE,
        payload: personnage
    })
}

export const putPlayerCompetence = (id, competenceToAdd) => async dispatch => {
    let personnage = await addCompetence(id, competenceToAdd);
    return dispatch({
        type: types.PUT_PLAYER_COMPETENCE,
        payload: personnage
    })
}

export const putPlayerEquipement = (id, idEquipementToAdd) => async dispatch => {
    let personnage = await addEquipement(id, idEquipementToAdd);
    return dispatch({
        type: types.PUT_PLAYER_EQUIPEMENT,
        payload: personnage
    })
}
