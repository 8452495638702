import React, {Component} from 'react'
import {connect} from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import {getPlayer} from '../../store/player/playerSelector'
import PlayerStats from '../player/PlayerStats'
import HeroChoiceDialog from './HeroChoiceDialog'
import PlayerActions from '../player/PlayerActions'
import './Board.scss'
import PlayerCompteurs from '../player/PlayerCompteurs'

class Board extends Component {
	constructor(props) {
		super()
		this.state = {heroSelectorOpened: false}
	}

    openHeroSelector = () => this.setState({...this.state, heroSelectorOpened: true})
	closeHeroSelector = () => this.setState({...this.state, heroSelectorOpened: false})

	render() {
		const {player} = this.props

		return (
		  <div className="board">
			  {
				  !player &&
				  <div className="board-header">
					  <div>
						  <MenuIcon onClick={this.openHeroSelector} className="icon"/>
					  </div>
				  </div>
			  }
			  {
			  	player &&
				  <div className="player-container">
					  <PlayerStats />
					  <PlayerCompteurs />
					  <PlayerActions />
				  </div>
			  }
			  <HeroChoiceDialog handleClose={this.closeHeroSelector} open={this.state.heroSelectorOpened} />
		  </div>
		)
	}
}

const mapStateToProps = (state, props) => ({
	player: getPlayer(state)
})

export default connect(
  mapStateToProps, {}
) (Board)
