import React, {Component} from 'react'
import './resources/font/HEROQUESTCYRUNICORN.TTF'
import './resources/styles/styles.scss'
import {Provider} from 'react-redux'
import configureStore from './store/configureStore'
import * as session from './storage/SessionStorage'
import Board from './components/board/Board'
import * as AuthService from './api/AuthService'
import {loadNiveauxCompetence} from './store/parametrage/parametrageActions'
import {Loader} from './components/loader/Loader'

const store = configureStore({
})

export default class App extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
	}

	authenticate = async () => {
		// const accessToken = session.loadAccessToken()
		// if(accessToken === null) {
			const res = await AuthService.authenticate('app', '413985f0-8bc7-46bf-8533-808cbd4b5304', true)
			session.storeAccessToken(res.id_token);
			await store.dispatch(loadNiveauxCompetence());
			this.setState({loading: false});
		// } else {
		// 	await store.dispatch(loadNiveauxCompetence());
		// 	this.setState({loading: false});
		// }
	}

	componentWillMount() {
		this.authenticate().then(() => {})
	}

	render() {
		const {loading} = this.state;
		return (
		  <Provider store={store}>
			  {!loading &&
			  <div className="main">
				  <Board/>
			  </div>
			  }
			  {
			  	loading &&
				 <div className="loading-screen">
			        <Loader color="white" />
				 </div>
			  }
		  </Provider>
		)
	}
}
