import React from 'react'
import './SkillField.scss'

export const SkillField = ({type, value, size, className}) => {
	const sizeStyle = size === "small" ? "small" : ""
	return (
	  <div className={`stat-field ${sizeStyle} ${className}`}>
		  <div className="logo-container">
			  <div className={`logo type-${type}`}></div>
		  </div>
		  <div className="field">
			  <span className="digit">{value}</span>
		  </div>
	  </div>
	)
}
