import ApiError from './ApiError'
import qs from 'qs'
import serializeFormValues from './serialize'

const buildParams = params => (
    qs.stringify(params, {
        skipNulls: true,
        arrayFormat: 'repeat'
    })
)


const _request = async (method, params, verb, token, multipart) => {
    const headerArgs = {}
    if (token) {
        headerArgs.Authorization = `Bearer ${token}`
    }
    if (!multipart) {
        headerArgs['content-type'] = 'application/json'
    }

    let args = ''
    if (verb === 'GET') {
        args = buildParams(params)
        args = args === '' ? '' : (`?${args}`)
    }

    // const url = 'http://localhost:8095/api' + method + args
    const url = 'https://hero-quest.herokuapp.com/api' + method + args
    const body = (verb !== 'GET') ? params && JSON.stringify(params) : undefined

    try {
        const response = await fetch(url, {
            method: verb,
            headers: {
                ...headerArgs,
                'cache-control': 'no-cache, no-store'
            },
            body: multipart ? serializeFormValues(params) : body
        })
        if (!response.ok) {
            if (response.status === 401) {
                // non autorise
                throw new ApiError(401)
            }

            let result = null
            try {
                result = await response.json()
            } catch (e) {
                // no json content
            }
            throw new ApiError(response.status, result)
        }

        if (response.status === 204) {
            // no content
            return {}
        }

        // json
        const contentType = response.headers.get('content-type')
        if (contentType) {
            if (contentType.indexOf('application/json') !== -1) {
                return await response.json()
            } else if (contentType.indexOf('text/') !== -1) {
                return await response.text()
            }
        }
        // others
        return await response
    } catch (error) {
        console.log(error)
        if (error instanceof ApiError) {
            throw error
        }
        throw new ApiError(500, 'API unreachable')
    }
}

export const get = async (method, params, token) => {
    return _request(method, params, 'GET', token)
}

export const post = async (method, params, token) => {
    return _request(method, params, 'POST', token)
}

export const put = async (method, params, token) => {
    return _request(method, params, 'PUT', token)
}

export const patch = async (method, params, token) => {
    return _request(method, params, 'PATCH', token)
}

export const postMultipart = (method, params, token) => {
    return _request(method, params, 'POST', token, true)
}
