import React, {PureComponent} from 'react'
import Dialog from '@material-ui/core/Dialog'
import connect from 'react-redux/es/connect/connect'
import * as playerActions from '../../store/player/playerActions'
import {getPlayer} from '../../store/player/playerSelector'
import {getParametrageNiveauxCompetenceMap} from '../../store/parametrage/parametrageSelector'
import {DialogContent} from '@material-ui/core'
import './LevelUpDialog.scss'
import {SkillField} from './skill/SkillField'
import {StatValue} from './compteur/StatCompteur'
import {spring} from 'react-motion/lib/react-motion'
import Motion from 'react-motion/lib/Motion'
import presets from 'react-motion/lib/presets'

class LevelUpDialog extends PureComponent {

	state = {competenceSelected: null}

	clearState = () => {
		this.setState({competenceSelected: null})
	}

    selectCompetence(newCompetence) {
    	this.setState({competenceSelected: newCompetence} )
    }

	levelUp(newCompetence) {
		if (!newCompetence) return;
		const {player, putPlayerCompetence, handleClose} = this.props;
		putPlayerCompetence(player.id, newCompetence);
		handleClose();
	}

    getCompetence = (competence) => {
        const {player} = this.props
        return player.competences.slice().reverse().find(t => t.competence === competence);
    }

    getNextCompetence = (competence) => {
        const {niveauxCompetenceMap, player} = this.props
        return niveauxCompetenceMap.find(
            t => t.classe === player.classe
                && t.competence === competence
                && t.index === this.getCompetence(competence).index + 1);
    }

	render() {
		const {handleClose, open, player, niveauxCompetenceMap} = this.props
		const {competenceSelected} = this.state

        if(!player || !niveauxCompetenceMap) return <div/>;

		const actualCompetences = {
			vie: this.getCompetence('VIE'),
            mana: this.getCompetence('MANA'),
            esprit: this.getCompetence('ESPRIT'),
            cac: this.getCompetence("CORPS_A_CORPS"),
            tir: this.getCompetence("TIR"),
            defense: this.getCompetence("DEFENSE"),
            magie: this.getCompetence("MAGIE")
        }

		const newCompetences = {
            vie: this.getNextCompetence('VIE'),
            mana: this.getNextCompetence('MANA'),
            esprit: this.getNextCompetence('ESPRIT'),
            cac: this.getNextCompetence("CORPS_A_CORPS"),
            tir: this.getNextCompetence("TIR"),
            defense: this.getNextCompetence("DEFENSE"),
            magie: this.getNextCompetence("MAGIE")
		}

		const isVieSelected = competenceSelected && competenceSelected.id === newCompetences.vie.id
		const isEspritSelected = competenceSelected && competenceSelected.id === newCompetences.esprit.id
		const isManaSelected = competenceSelected && competenceSelected.id === newCompetences.mana.id
		const isCacSelected = competenceSelected && competenceSelected.id === newCompetences.cac.id
		const isTirSelected = competenceSelected && competenceSelected.id === newCompetences.tir.id
		const isDefenseSelected = competenceSelected && competenceSelected.id === newCompetences.defense.id
		const isMagieSelected = competenceSelected && competenceSelected.id === newCompetences.magie.id

		const offsetArrowDefault = 3
		const offsetArrowSelected = 12

		return (
		  <Dialog
		    onClose={handleClose}
		    aria-labelledby="simple-dialog"
		    open={open}
		    fullWidth
		    onEnter={this.clearState}
		    className="level-up-dialog"
		    disableBackdropClick>
			  <DialogContent>
				  <div className="level-up-dialog-title">
					  Level Up
				  </div>
				  <div className="level-up-dialog-content">
					  <div className="level-up-stat-list">
						  <div className={`level-up-stat-row ${isVieSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.vie)}>
							  <StatValue value={actualCompetences.vie.niveau} color="red"></StatValue>
							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isVieSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <StatValue value={newCompetences.vie.niveau} color="red" className="next-level-stat"></StatValue>
						  </div>

						  <div className={`level-up-stat-row ${isEspritSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.esprit)}>
							  <StatValue value={actualCompetences.esprit.niveau} color="green"></StatValue>
							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isEspritSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <StatValue value={newCompetences.esprit.niveau} color="green" className="next-level-stat"></StatValue>
						  </div>

						  <div className={`level-up-stat-row ${isManaSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.mana)}>
							  <StatValue value={actualCompetences.mana.niveau} color="blue"></StatValue>

							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isManaSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <StatValue value={newCompetences.mana.niveau} color="blue" className="next-level-stat"></StatValue>
						  </div>

						  <div className="level-up-stat-spacing"></div>

						  <div className={`level-up-stat-row ${isCacSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.cac)}>
							  <SkillField value={actualCompetences.cac.niveau} type="cac"></SkillField>
							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isCacSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <SkillField value={newCompetences.cac.niveau} type="cac" className="next-level-skill"></SkillField>
						  </div>

						  <div className={`level-up-stat-row ${isTirSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.tir)}>
							  <SkillField value={actualCompetences.tir.niveau} type="tir"></SkillField>
							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isTirSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <SkillField value={newCompetences.tir.niveau} type="tir" className="next-level-skill"></SkillField>
						  </div>

						  <div className={`level-up-stat-row ${isDefenseSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.defense)}>
							  <SkillField value={actualCompetences.defense.niveau} type="defense"></SkillField>
							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isDefenseSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <SkillField value={newCompetences.defense.niveau} type="defense" className="next-level-skill"></SkillField>
						  </div>

						  <div className={`level-up-stat-row ${isMagieSelected && "selected"}`} onClick={() => this.selectCompetence(newCompetences.magie)}>
							  <SkillField value={actualCompetences.magie.niveau} type="mana"></SkillField>
							  <div className="arrow-container">
								  <Motion defaultStyle={{marginLeft: offsetArrowDefault}} style={{marginLeft: spring(isMagieSelected ? offsetArrowSelected : offsetArrowDefault)}}>
									  {interpolatingStyle => <div className="arrow-right" style={{marginLeft: `${interpolatingStyle.marginLeft}px`}} /> }
								  </Motion>
							  </div>
							  <SkillField value={newCompetences.magie.niveau} type="mana" className="next-level-skill"></SkillField>
						  </div>
						  {
							  competenceSelected &&
							  <Motion defaultStyle={{bottom: 30, zIndex: -1}} style={{bottom: spring(0, presets.wobbly), zIndex: spring(0)}}>
								  {interpolatingStyle => (
								    <div
								      className={`level-up-dialog-validate-btn ${!competenceSelected && "disabled"}`}
								      onClick={() => this.levelUp(competenceSelected)}
								      style={{
								      	bottom: `${interpolatingStyle.bottom}px`,
								        zIndex: interpolatingStyle.zIndex
								      }}
								    >
									    Valider
								    </div>
								  )}
							  </Motion>
						  }
					  </div>
				  </div>
			  </DialogContent>
		  </Dialog>
		)
	}

}


const mapStateToProps = (state, props) => ({
    player: getPlayer(state),
    niveauxCompetenceMap: getParametrageNiveauxCompetenceMap(state)
})

export default connect(
  mapStateToProps, {
      loadPlayerAction: playerActions.loadPlayer,
        putPlayerCompetence: playerActions.putPlayerCompetence
  }
) (LevelUpDialog)
