import React, {PureComponent} from 'react'
import Dialog from '@material-ui/core/Dialog'

class Popup extends PureComponent {

	render() {
		const {message, titre, handleClose, open} = this.props

		return (
		  <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth>
              {titre &&
                  <div style={{"textAlign": "center"}}>
                      <h2>{titre}</h2>
                  </div>
              }
              {message &&
                  <div style={{"textAlign": "center"}}>
                      <p>{message}</p>
                  </div>
              }
		  </Dialog>
		)
	}

}

export default Popup
