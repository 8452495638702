import {DiceFaceEnum} from './DiceFaceEnum'

export const DiceEnum = {
	D6: {
		color: '#DB0000',
		faces: ['1', '2', '3', '4', '5', '6']
	},
	BLANC: {
		color: '#FFFFFF',
		faces: [DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.BOUCLIER, DiceFaceEnum.BOUCLIER, DiceFaceEnum.MONSTRE]
	},
	VERT: {
		color: '#33CD66',
		faces: [DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.BOUCLIER, DiceFaceEnum.BOUCLIER, DiceFaceEnum.BOUCLIER, DiceFaceEnum.MONSTRE]
	},
	BLEU: {
		color: '#6699FF',
		faces: [DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.BOUCLIER, DiceFaceEnum.MONSTRE, DiceFaceEnum.MONSTRE]
	},
	NOIR: {
		color: '#000000',
		faces: [DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.BOUCLIER, DiceFaceEnum.MONSTRE]
	},
	VIOLET: {
		color: '#A700CB',
		faces: [DiceFaceEnum.CRANE, DiceFaceEnum.CRANE, DiceFaceEnum.CRANE_DOUBLE, DiceFaceEnum.BOUCLIER, DiceFaceEnum.BOUCLIER_DOUBLE, DiceFaceEnum.MONSTRE_DOUBLE]
	},
	ORANGE: {
		color: '#FF8533',
		faces: [
			DiceFaceEnum.CRANE_DOUBLE,
			DiceFaceEnum.CRANE_DOUBLE,
			DiceFaceEnum.BOUCLIER_DOUBLE,
			DiceFaceEnum.BOUCLIER_DOUBLE,
			DiceFaceEnum.MONSTRE_DOUBLE,
			DiceFaceEnum.MONSTRE_DOUBLE
		]
	},
	JAUNE: {
		color: '#FFCC00',
		faces: [DiceFaceEnum.CRANE, DiceFaceEnum.CRANE_DOUBLE, DiceFaceEnum.BOUCLIER, DiceFaceEnum.BOUCLIER_DOUBLE, DiceFaceEnum.MONSTRE, DiceFaceEnum.MONSTRE_DOUBLE]
	},
	MAGIE_VERT: {
		color: '#33CD66',
		faces: [DiceFaceEnum.REUSSITE, DiceFaceEnum.REUSSITE, DiceFaceEnum.REUSSITE, DiceFaceEnum.ECHEC, DiceFaceEnum.ECHEC, DiceFaceEnum.ECHEC]
	},
	MAGIE_BLEU: {
		color: '#6699FF',
		faces: [DiceFaceEnum.REUSSITE_CRITIQUE, DiceFaceEnum.REUSSITE, DiceFaceEnum.REUSSITE, DiceFaceEnum.REUSSITE, DiceFaceEnum.ECHEC, DiceFaceEnum.ECHEC]
	},
	MAGIE_ORANGE: {
		color: '#FF8533',
		faces: [DiceFaceEnum.REUSSITE_CRITIQUE, DiceFaceEnum.REUSSITE_CRITIQUE, DiceFaceEnum.REUSSITE, DiceFaceEnum.REUSSITE, DiceFaceEnum.REUSSITE, DiceFaceEnum.ECHEC]
	}
};
