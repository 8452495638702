import React, {PureComponent} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {connect} from "react-redux";
import {getPlayer} from "../../store/player/playerSelector";
import {Dice} from "../dice/Dice";
import {DiceEnum} from "../../types/DiceEnum";
import {DiceFaceEnum} from "../../types/DiceFaceEnum";
import * as playerActions from "../../store/player/playerActions";

class AttaqueDefenseDialog extends PureComponent {

    initialState = {diceThrowDialogOpened: false, actionSelection: '', diceToLaunch: [], diceSuppToLaunch: []}

    constructor(props) {
        super(props)
        this.state = {diceThrowDialogOpened: false, actionSelection: '', diceToLaunch: [], diceSuppToLaunch: []}
    }

    handleClose = () => {
        this.setState({...this.initialState});
        this.props.handleClose();
    }

    selectAction = (key, dice) => {
        if(this.state.actionSelection === key) {
            this.setState({actionSelection: this.initialState.actionSelection, diceToLaunch: this.initialState.diceToLaunch})
        }
        else {
            this.setState({actionSelection: key, diceToLaunch: dice})
        }
    };

    selectDiceSupp = (key) => {
        let selection = [...this.state.diceSuppToLaunch];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        } else {
            selection.push(key);
        }
        this.setState({ diceSuppToLaunch: selection });
    }

    isActionSelected = key => this.state.actionSelection.includes(key)

    isDiceSuppSelected = key => this.state.diceSuppToLaunch.includes(key)

    lancer = () => {
        const {callback, patchPlayerEsprit, player} = this.props;
        const {actionSelection, diceToLaunch, diceSuppToLaunch} = this.state;

        if(actionSelection === 'DEF_CA') {
            patchPlayerEsprit(player.id, player.esprit - 1);
        }

        callback(diceToLaunch.concat(diceSuppToLaunch), actionSelection === 'DEF_CA');
    }

	render() {
		const {player, open} = this.props

		if(!player || !player.equipements) return <div/>

		const widthDe = "30px"

        const equipementsAttaque = player.equipements.filter(e => e.desAttaque!==undefined && e.desAttaque!==null);
        const equipementDefaut = player.equipements.find(e => e.defaut===true);
        const equipementsDefense = player.equipements.filter(e => e.desDefense!==undefined && e.desDefense!==null);
        const desDefense = [];
        equipementsDefense.forEach(e => desDefense.push.apply(desDefense, e.desDefense));

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true}>

                <p style={{"textAlign": "center", "fontWeight": "bold"}}>Attaque</p>
                {equipementsAttaque && equipementsAttaque.map((eq, key) => {
                    let des = eq.desAttaque;
                    if(equipementDefaut && eq.hasPlus) {
                        des = des.concat(equipementDefaut.desAttaque);
                    }
                    return (
                        <div key={key}
                                  style={{ backgroundColor: this.isActionSelected("ATT_" + eq.id) ? '#fff8b0' : '#ffffff' }}
                                  onClick={() => this.selectAction("ATT_" + eq.id, des)}>
                            <div style={{ "display": "inline-block", "verticalAlign": "middle", "height": "30px" }}><p style={{"fontSize": "12px"}}>{eq.nom}</p></div>
                            <div style={{ "display": "inline-block", "maxWidth" : "50%", "float": "right" }}>{des.map((de, key) => (<Dice key={key} faceUp={DiceFaceEnum.CRANE} dice={DiceEnum[de]} width={widthDe}/>))}</div>
                        </div>
                    )
                })}

                <hr style={{ "width": "100%" }} />

                <p style={{"textAlign": "center", "fontWeight": "bold"}}>Défense</p>
                {desDefense && desDefense.length > 0 && (
                    <div>
                        <div style={{ backgroundColor: this.isActionSelected("DEF_N") ? '#fff8b0' : '#ffffff' }}
                             onClick={() => this.selectAction("DEF_N", desDefense)}>
                            <div style={{ "display": "inline-block", "verticalAlign": "middle", "height": 30 * equipementsDefense.length + "px" }}>{equipementsDefense.map((eq, key) => <p key={key} style={{"fontSize": "12px"}}>{eq.nom}</p>)}</div>
                            <div style={{ "display": "inline-block", "maxWidth" : "50%", "float": "right" }}>{desDefense.map((de, key) => (<Dice key={key} faceUp={DiceFaceEnum.BOUCLIER} dice={DiceEnum[de]} width={widthDe}/>))}</div>
                        </div>
                        {player && player.esprit > 0 && (
                            <div style={{backgroundColor: this.isActionSelected("DEF_CA") ? '#fff8b0' : '#ffffff'}}
                                 onClick={() => this.selectAction("DEF_CA", desDefense)}>
                                <div style={{"display": "inline-block", "verticalAlign": "middle", "height": "30px"}}><p style={{"fontSize": "12px"}}>avec contre-attaque (-1 esprit)</p></div>
                            </div>
                        )}
                    </div>
                )}

                <hr style={{ "width": "100%" }} />
                <p style={{"textAlign": "center", "fontWeight": "bold"}}>Magie</p>
                <div style={{"textAlign": "center"}}>
                    <div key="Magie1"
                         style={{ backgroundColor: this.isActionSelected("MAG_1") ? '#fff8b0' : '#ffffff', "width": "33%", "display": "inline-block" }}
                         onClick={() => this.selectAction("MAG_1", ["MAGIE_VERT"])}>
                        <Dice faceUp={DiceFaceEnum.REUSSITE} dice={DiceEnum["MAGIE_VERT"]} width={widthDe} />
                    </div>
                    <div key="Magie2"
                         style={{ backgroundColor: this.isActionSelected("MAG_2") ? '#fff8b0' : '#ffffff', "width": "33%", "display": "inline-block" }}
                         onClick={() => this.selectAction("MAG_2", ["MAGIE_BLEU"])}>
                        <Dice faceUp={DiceFaceEnum.REUSSITE} dice={DiceEnum["MAGIE_BLEU"]} width={widthDe} />
                    </div>
                    <div key="Magie3"
                         style={{ backgroundColor: this.isActionSelected("MAG_3") ? '#fff8b0' : '#ffffff', "width": "33%", "display": "inline-block" }}
                         onClick={() => this.selectAction("MAG_3", ["MAGIE_ORANGE"])}>
                        <Dice faceUp={DiceFaceEnum.REUSSITE} dice={DiceEnum["MAGIE_ORANGE"]} width={widthDe} />
                    </div>
                </div>

                <hr style={{ "width": "100%" }} />

                <p style={{"textAlign": "center", "fontWeight": "bold"}}>Dés supplémentaires</p>
                <div style={{"textAlign": "center"}}>
                    <div onClick={() => this.selectDiceSupp("D6")} style={{backgroundColor: this.isDiceSuppSelected("D6") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp="6" dice={DiceEnum["D6"]} width={widthDe} />
                    </div>
                    <div onClick={() => this.selectDiceSupp("BLANC")} style={{backgroundColor: this.isDiceSuppSelected("BLANC") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["BLANC"]} width={widthDe} />
                    </div>
                    <div onClick={() => this.selectDiceSupp("VERT")} style={{backgroundColor: this.isDiceSuppSelected("VERT") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["VERT"]} width={widthDe} />
                    </div>
                    <div onClick={() => this.selectDiceSupp("BLEU")} style={{backgroundColor: this.isDiceSuppSelected("BLEU") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["BLEU"]} width={widthDe} />
                    </div>
                </div>
                <div style={{"textAlign": "center"}}>
                    <div onClick={() => this.selectDiceSupp("NOIR")} style={{backgroundColor: this.isDiceSuppSelected("NOIR") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["NOIR"]} width={widthDe} />
                    </div>
                    <div onClick={() => this.selectDiceSupp("VIOLET")} style={{backgroundColor: this.isDiceSuppSelected("VIOLET") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["VIOLET"]} width={widthDe} />
                    </div>
                    <div onClick={() => this.selectDiceSupp("ORANGE")} style={{backgroundColor: this.isDiceSuppSelected("ORANGE") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["ORANGE"]} width={widthDe} />
                    </div>
                    <div onClick={() => this.selectDiceSupp("JAUNE")} style={{backgroundColor: this.isDiceSuppSelected("JAUNE") ? '#fff8b0' : '#ffffff', "display": "inline-block", "width": "25%"}}>
                        <Dice faceUp={DiceFaceEnum.CRANE} dice={DiceEnum["JAUNE"]} width={widthDe} />
                    </div>
                </div>

                <hr style={{ "width": "100%" }} />

                <button
                    disabled={this.state.diceToLaunch.length === 0 && this.state.diceSuppToLaunch.length === 0}
                    onClick={this.lancer}
                    style={{"fontSize": "15px"}}
                >LANCER</button>

            </Dialog>
        )
    }

}

const mapStateToProps = (state, props) => ({
    player: getPlayer(state)
})

export default connect(
    mapStateToProps, {
        patchPlayerEsprit: playerActions.patchPlayerEsprit,
    }
) (AttaqueDefenseDialog)
