import {applyMiddleware, createStore, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { createLogger } from 'redux-logger'

let enhancers
export default function configureStore(initialState) {
	const store = createStore(
        rootReducer,
        initialState,
	    compose(...enhancers)
    )
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			store.replaceReducer(require('./reducers'))
		})
	}
	return store
}



if (process.env.NODE_ENV === 'production') {
    enhancers = [applyMiddleware(thunk)]
} else {
    // DEV
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        // utilisation de redux dev tools uniquement
        enhancers = [applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__()]
    } else {
        // à défaut, utilisation du redux-logger middleware
        const loggerMiddleware = createLogger({
            level: 'info',
            collapsed: true
        })
        enhancers = [applyMiddleware(loggerMiddleware, thunk)]
    }
}
