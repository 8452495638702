import {get} from './Service'
import * as SessionStorage from "../storage/SessionStorage";

export const getOne = (id) => {
    return get(`/equipements/${id}`, null, SessionStorage.loadAccessToken())
};

export const getCarte = (id) => {
    return get(`/equipements/${id}/image`, null, SessionStorage.loadAccessToken())
        .then((response) => {
            return response.arrayBuffer().then((buffer) => {
                let binary = '';
                [].slice.call(new Uint8Array(buffer)).forEach((b) => binary += String.fromCharCode(b));
                return 'data:image/jpeg;base64,' + window.btoa(binary);
            });
        });
};
