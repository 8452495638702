import {types} from './playerActions'


const player = (state = null, action) => {
	switch (action.type) {
		case types.LOAD_PLAYER: {
			if (action.payload &&
			  action.payload.classe !== null) {
				return action.payload
			}
			return null
		}
		case types.PATCH_PLAYER_VIE: {
			return {
				...state,
				vie: action.payload
			}
		}
		case types.PATCH_PLAYER_ESPRIT: {
			return {
				...state,
				esprit: action.payload
			}
		}
		case types.PATCH_PLAYER_MANA: {
			return {
				...state,
				mana: action.payload
			}
		}
		case types.PATCH_PLAYER_EXPERIENCE:
		case types.PATCH_PLAYER_GOLD:
		case types.PUT_PLAYER_COMPETENCE:
        case types.PUT_PLAYER_EQUIPEMENT: {
			return action.payload;
		}
		default:
			return state
	}
}

export default player
