import {types} from './personnagesActions'


const personnages = (state = null, action) => {
	switch (action.type) {
		case types.LOAD_PERSONNAGES: {
			return action.payload
		}
		default:
			return state
	}
}

export default personnages
