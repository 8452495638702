import * as ParametrageService from '../../api/ParametrageService'
import * as SessionStorage from '../../storage/SessionStorage'
const ACTION_NAMESPACE = '/PARAMETRAGE'

export const types = {
    LOAD_PARAMETRAGE_NIVEAUX_COMPETENCE: `${ACTION_NAMESPACE}/GET_NIVEAUX_COMPETENCE`,
}

export const loadNiveauxCompetence = () => async (dispatch) => {
    const token = SessionStorage.loadAccessToken();
    try {
        let niveauxCompetence = await ParametrageService.getAllNiveauxCompetence(token)
        return dispatch({
            type: types.LOAD_PARAMETRAGE_NIVEAUX_COMPETENCE,
            payload: niveauxCompetence
        })
    } catch(e) {
        console.error(e)
    }
}

