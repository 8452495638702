import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class ImageDialog extends Component {

	render() {
		const {image, titre, handleClose, open} = this.props

        const styles = theme => ({
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

        const DialogTitle = withStyles(styles)(props => {
            const { children, classes, onClose, ...other } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });

		return (
			<Dialog onClose={handleClose} open={open} fullWidth={true}>
                {titre && (
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {titre}
                    </DialogTitle>
                )}
                {image && (
                    <DialogContent>
                        <img src={image} width={"100%"} alt={"HQ"} />
                    </DialogContent>
                )}
			</Dialog>
		)
	}
}

export default ImageDialog
